import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { OpenInOtherTabGuard } from 'src/app/core/guards/open-in-other-tab.guard';
import { LegacyRenderComponent } from '../../pages/legacy-render/legacy-render.component';

export const INSTITUTION_ADMIN_LEGACY_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/'
  },
  {
    path: 'panel', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Painel',
      legacyRoute: 'instituicao/default.aspx',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'DashboardAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'home'
    },
  },
  {
    path: 'candidates', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Candidatos',
      legacyRoute: 'portal/candidatos',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: ['InternshipModuleAccess', 'OpportunityModuleAccess'],
        redirectTo: '/unauthorized'
      },
      icon: 'black-tie'
    },
  },
  {
    path: 'companies', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Empresas',
      legacyRoute: 'portal/empresas',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: ['InternshipModuleAccess', 'OpportunityModuleAccess'],
        redirectTo: '/unauthorized'
      },
      icon: 'building'
    },
  },
  {
    path: 'agreements', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Convênios',
      legacyRoute: 'm/convenio/instituicao',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'AgreementModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'check-square-o'
    }
  },
  {
    path: 'internships', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Estágios',
      legacyRoute: 'estagios',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'InternshipModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'graduation-cap'
    },
  },
  {
    path: 'internships/follow-up', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Estágios',
      legacyRoute: 'estagios/gestao/#/configurar/acompanhamentos',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'InternshipModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'graduation-cap',
      notInHeaderMenu: true,

    },
  },
  {
    path: 'internships/view', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Estágios',
      legacyRoute: 'estagios/visualizar.aspx',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'InternshipModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'graduation-cap',
      notInHeaderMenu: true,
    },
  },
  {
    path: 'leads', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Leads',
      legacyRoute: 'instituicao/leads/default.aspx',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'ManageLeadAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'circle'
    },
  },
  {
    path: 'jobs', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Vagas',
      legacyRoute: 'vaga/lista.aspx',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'OpportunityModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'suitcase'
    },
  },
  {
    path: 'jobs/view', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Vagas',
      legacyRoute: 'vaga/visualizar.aspx',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'OpportunityModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'suitcase',
      notInHeaderMenu: true,
    },
  },
  {
    path: 'open-jobs', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Vagas Abertas',
      legacyRoute: 'vagas',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'OpportunityModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'suitcase',
      notInHeaderMenu: true,
    },
  },
  {
    path: 'campus', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Campus',
      legacyRoute: 'm/instituicao/campus',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'InternshipModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'university'
    },
  },
  {
    path: 'campaign', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Campanhas',
      legacyRoute: 'campanhas/gestao/#/configurar',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'CampaignModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'question-circle'
    },
  },
  {
    path: 'team', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Equipe',
      legacyRoute: 'instituicao/equipe',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'TeamVisualize',
        redirectTo: '/unauthorized'
      },
      icon: 'users'
    },
  },
  {
    path: 'integration', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Integração',
      legacyRoute: 'instituicao/estudante/integracao.aspx',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'ManageIntegrationAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'exchange'
    },
  },
  {
    path: 'reports', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Relatórios',
      legacyRoute: 'configuracoes/relatorios',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'ManageReportAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'book'
    },
  },
  {
    path: 'change-password', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Alterar senha',
      legacyRoute: 'comuns/trocasenhausuario.aspx',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'PasswordChange',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 1
    },
  },
  {
    path: 'digital-password', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Senha digital',
      legacyRoute: 'configuracoes/conta/senha-digital',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'DigitalSignaturePasswordChange',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 2
    },
  },
  {
    path: 'personal-data', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Dados Pessoais',
      legacyRoute: 'comuns/dadosusuario.aspx',
      roles: ['Administrador de Instituição'],
      permissions: {
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 3
    },
  },
  {
    path: 'support', component: LegacyRenderComponent,
    canActivate: [AuthGuard, OpenInOtherTabGuard, NgxPermissionsGuard],
    data: {
      name: 'Central de Suporte',
      legacyRoute: 'atendimento/m/acesso',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'ZenDeskTicketRegister',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 6
    },
  },
  {
    path: 'privacy-policy',
    component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      externalUrl: `https://www.symplicity.com/support/privacy-policy-pt`,
      name: 'Política de Privacidade',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'PrivacyPolicyVisualize',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 7
    },
  },
  {
    path: 'curriculum',
    redirectTo: 'candidates'
  },
  {
    path: 'curriculum-view', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      notInHeaderMenu: true,
      name: 'Candidatos',
      legacyRoute: 'estudante/curriculos/visualizarcurriculo.aspx',
      roles: ['Administrador de Instituição'],
      permissions: {
        only: ['InternshipModuleAccess', 'OpportunityModuleAccess'],
        redirectTo: '/unauthorized'
      },
      icon: 'black-tie'
    },
  },
  {
    path: 'document',
    component: LegacyRenderComponent,
    data: {
      name: 'Documento',
      legacyRoute: 'estagio/documento',
      notInHeaderMenu: true,
      hideHeader: true,
      hideFooter: true
    },
  },
  {
    path: 'document/:id',
    component: LegacyRenderComponent,
    data: {
      name: 'Documento',
      legacyRoute: 'estagio/documento',
      notInHeaderMenu: true,
      hideHeader: true,
      hideFooter: true
    },
  },
  {
    path: 'document/:system/:type/:name/:id',
    component: LegacyRenderComponent,
    data: {
      name: 'Documento',
      legacyRoute: 'estagios/documentos',
      notInHeaderMenu: true,
      hideHeader: true,
      hideFooter: true
    },
  },
  {
    path: 'monitoring-document',
    component: LegacyRenderComponent,
    data: {
      name: 'Documento',
      legacyRoute: 'documentacao/templateacompanhamento.aspx',
      notInHeaderMenu: true,
      hideHeader: true,
      hideFooter: true
    },
  },
  {
    path: 'internship/audit/:id', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Auditoria do estágio',
      legacyRoute: 'm/estagio/auditoria',
      notInHeaderMenu: true,
      roles: ['Administrador de Instituição'],
      permissions: {
        only: 'InternshipAuditVisualize',
        redirectTo: '/unauthorized'
      },
      icon: 'certificate'
    },
  },
  {
    path: 'student-approval', component: LegacyRenderComponent,
    canActivate: [AuthGuard],
    data: {
      notInHeaderMenu: true,
      name: 'Aprovar Candidatos',
      legacyRoute: 'instituicao/estudante/aprovacaoestudante.aspx',
      roles: ['Administrador de Instituição'],
      icon: 'black-tie'
    },
  },
  {
    path: 'internship/invalidate-contract/:id', component: LegacyRenderComponent,
    canActivate: [AuthGuard],
    data: {
      notInHeaderMenu: true,
      name: 'Invalidar Contrato',
      legacyRoute: 'm/estagio/contrato-de-estagio/invalidar-formulario',
      roles: ['Administrador de Instituição'],
      icon: 'black-tie'
    },
  },
];
